import { useState, useEffect, useCallback } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import logo from "../assets/logo.png";
import Button from "../UIComponents/Button";

import { AnimatedDiv } from "../Animation";

const Navbar = ({
  setIsContactMenuShown,
  setMobileNavMenuShown,
  mobileNavMenuShown,
}) => {
  const navigation = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const [logoSize, setLogoSize] = useState(120);

  const mobileNavVarients = {
    open: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
        type: "Inertia",
        delayChildren: 0.1,
        staggerDirection: -1,
      },
    },
    closed: {
      opacity: 0,
      y: "-100vh",
      transition: {
        type: "Inertia",
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  useEffect(() => {
    if (window.innerWidth < 500) {
      setLogoSize(100);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 590 && window.innerWidth < 500) {
        setLogoSize(72);
      } else if (window.scrollY > 590 || window.innerWidth > 500) {
        setLogoSize(120);
      } else {
        setLogoSize(100);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getLogoHeight = useCallback(() => {
    return { width: `${logoSize}px`, height: `${logoSize}px` };
  }, [logoSize]);

  const getMarginTopForLogo = useCallback(() => {
    if (window.innerWidth < 500 && window.scrollY > 590) {
      return { marginTop: "0" };
    } else if (window.scrollY > 590 || window.innerWidth > 500) {
      return { marginTop: "36px" };
    }
    return { marginTop: "26px" };
  }, []);

  useEffect(() => {
    const rerunFunctions = () => {
      getMarginTopForLogo();
      getLogoHeight();
    };
    window.addEventListener("resize", rerunFunctions);

    return () => {
      window.removeEventListener("resize", rerunFunctions);
    };
  }, [getLogoHeight, getMarginTopForLogo]);

  useEffect(() => {
    const hideMobileNavMenu = () => {
      if (window.innerWidth > 1024) {
        setMobileNavMenuShown(false);
      }
    };
    window.addEventListener("resize", hideMobileNavMenu);

    return () => {
      window.removeEventListener("resize", hideMobileNavMenu);
    };
  });

  return (
    <header className="h-[96px] w-full border-[1px] border-borders flex justify-between items-center fixed  top-0 bg-page z-40">
      <AnimatedDiv
        animate
        className={`ml-[25px] md:ml-[61px]`}
        style={{
          width: getLogoHeight().width,
          height: getLogoHeight().height,
          marginTop: getMarginTopForLogo().marginTop,
        }}
      >
        <LazyLoadImage
          src={logo}
          width="100%"
          height="100%"
          onClick={() => navigation("/")}
          effect="blur"
          alt="CL new logo"
          className="object-fill cursor-pointer duration-300"
        />
      </AnimatedDiv>

      {/*  menu and contact buttons For Mobile View*/}
      <div className="flex lg:hidden ">
        <div className="mr-3">
          <Button
            onClick={() => {
              console.log("here");
              setMobileNavMenuShown(true);
            }}
            backgroundColor="bg-transparent"
            text="Menu"
            color="text-brownish"
          />
        </div>

        <div className="mr-[17px]">
          <Button
            onClick={() => {
              setIsContactMenuShown(true);
            }}
            text="Contact Us"
            backgroundColor="bg-primary"
            color="text-white"
          />
        </div>
      </div>

      {/* Middle Section */}
      <nav className="hidden lg:flex items-center">
        <ul className="flex items-center gap-8 ">
          <li>
            <button
              onClick={() => navigation("/")}
              className={`${
                pathname === "/" ? "bg-paleBrown" : "bg-transparent"
              } p-2.5 rounded-md text-brownish text-sm sm:text-base font-normal hover:font-semibold duration-300`}
            >
              Home
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                navigation("/About");
              }}
              className={`${
                pathname === "/About" ? "bg-paleBrown" : "bg-transparent"
              } p-2.5 rounded-md text-brownish text-sm sm:text-base font-normal hover:font-semibold duration-300`}
            >
              About
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                window.open("https://classroom.google.com", "_blank").focus();
              }}
              className={`${
                pathname === "/Classroom" ? "bg-paleBrown" : "bg-transparent"
              } p-2.5 rounded-md text-brownish text-sm sm:text-base font-normal hover:font-semibold duration-300`}
            >
              Classroom
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                window
                  .open("https://clprivate.safsms.cloud/login.html", "_blank")
                  .focus();
              }}
              className={`${
                pathname === "/Student-Portal"
                  ? "bg-paleBrown"
                  : "bg-transparent"
              } p-2.5 rounded-md text-brownish text-sm sm:text-base font-normal hover:font-semibold duration-300`}
            >
              Student Portal
            </button>
          </li>
        </ul>
      </nav>

      {/*  Login and contact buttons For Desktop View*/}
      <div className="hidden lg:flex items-center mr-[110px]">
        <div className="mr-3">
          <Button
            onClick={() => {
              window
                .open("https://clprivate.safsms.cloud/login.html", "_blank")
                .focus();
            }}
            backgroundColor="bg-transparent"
            color="text-brownish"
            text="Login"
          />
        </div>

        {/* This button doesn't need to be in a div since it's parent has the margin right */}
        <Button
          onClick={() => {
            setIsContactMenuShown(true);
          }}
          text="Contact Us"
          backgroundColor="bg-primary"
          color="text-white"
        />
      </div>

      {/* Mobile Nav Menu */}

      <AnimatedDiv
        initial={false}
        variants={mobileNavVarients}
        animate={mobileNavMenuShown ? "open" : "closed"}
        exit={{ opacity: 0, y: "-100vh" }}
        className="fixed w-screen h-screen z-[51] top-0 left-0 bg-page flex flex-col pt-6 px-6"
      >
        <button
          className="self-end"
          onClick={() => {
            setMobileNavMenuShown(false);
          }}
        >
          Close
        </button>

        <nav className="mt-5">
          <ul className="flex flex-col gap-3">
            <li>
              <button
                onClick={() => {
                  if (pathname !== "/") navigation("/");
                  setMobileNavMenuShown(false);
                }}
                className={`${
                  pathname === "/" ? "bg-paleBrown" : "bg-transparent"
                } w-full text-left p-3 rounded-xl text-lg tracking-[-0.02em]`}
              >
                Home
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  if (pathname !== "/About") navigation("/About");
                  setMobileNavMenuShown(false);
                }}
                className={`${
                  pathname === "/About" ? "bg-paleBrown" : "bg-transparent"
                }  w-full text-left p-3 rounded-xl text-lg tracking-[-0.02em]`}
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  window.open("https://classroom.google.com", "_blank").focus();
                }}
                className={`${
                  pathname === "/Classroom" ? "bg-paleBrown" : "bg-transparent"
                } w-full text-left p-3 rounded-xl text-lg tracking-[-0.02em]`}
              >
                Classroom
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  window
                    .open("https://clprivate.safsms.cloud/login.html", "_blank")
                    .focus();
                }}
                className={`${
                  pathname === "/Student-Portal"
                    ? "bg-paleBrown"
                    : "bg-transparent"
                } w-full text-left p-3 rounded-xl text-lg tracking-[-0.02em]`}
              >
                Student Portal
              </button>
            </li>
          </ul>
        </nav>

        <div className="mt-auto mb-28 flex flex-col gap-3">
          <button
            onClick={() => {
              window
                .open("https://clprivate.safsms.cloud/login.html", "_blank")
                .focus();
            }}
            className="bg-transparent w-full rounded-md p-[0.625rem] text-brownish font-semibold text-base leading-5 tracking-[-0.02em]"
          >
            Login
          </button>
          <button
            className="bg-primary w-full rounded-md p-[0.625rem] text-white font-semibold text-base leading-5 tracking-[-0.02em]"
            onClick={() => {
              setIsContactMenuShown(true);
            }}
          >
            Contact Us
          </button>
        </div>
      </AnimatedDiv>
    </header>
  );
};

export default Navbar;
