import { TailSpin } from "react-loader-spinner";

export default function ReactLoader() {
  return (
    <div className="flex justify-center min-h-screen w-screen items-center bg-page">
      <TailSpin
        ariaLabel="loading-indicator"
        color="#00000059"
        height={70}
        width={70}
      />
    </div>
  );
}
