import { useState, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactLoader from "./Animation/loader";

// import About from "./pages/About/About";
import Home from "./pages/Home/Home";

const About = lazy(() => import("./pages/About/About"));

function App() {
  const [isContactMenuShown, setIsContactMenuShown] = useState(false);
  const [mobileNavMenuShown, setMobileNavMenuShown] = useState(false);

  return (
    <Suspense fallback={<ReactLoader />}>
      <div className=" bg-page overflow-hidden min-w-[320px] relative before:hidden lg:before:block before:absolute before:top-[0px] before:left-[120px] before:content-[''] before:w-[1px] before:h-full before:bg-underline ">
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isContactMenuShown={isContactMenuShown}
                  setIsContactMenuShown={setIsContactMenuShown}
                  mobileNavMenuShown={mobileNavMenuShown}
                  setMobileNavMenuShown={setMobileNavMenuShown}
                />
              }
            />
            <Route
              path="/about"
              element={
                <About
                  isContactMenuShown={isContactMenuShown}
                  setIsContactMenuShown={setIsContactMenuShown}
                  mobileNavMenuShown={mobileNavMenuShown}
                  setMobileNavMenuShown={setMobileNavMenuShown}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </Suspense>
  );
}

export default App;
