const Button = (props) => {
  const { backgroundColor, color, text, ...rest } = props;

  return (
    <button
      {...rest}
      className={`${backgroundColor} p-2.5 rounded-md ${color} text-sm sm:text-base font-semibold ${
        backgroundColor === "bg-transparent"
          ? "hover:bg-paleBrown"
          : "hover:bg-purpleish"
      } duration-300`}
    >
      {text}
    </button>
  );
};

export default Button;
