import { motion } from "framer-motion";
import xClose from "../assets/xClose.svg";

const ContactUs = ({
  isContactMenuShown,
  setIsContactMenuShown,
  setMobileNavMenuShown,
}) => {
  const contactMenuVariants = {
    open: {
      opacity: 1,
      y: 0,
      zIndex: 51,
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
    closed: {
      y: "-100vh",
      transition: {
        type: "tween",
        duration: 0.5,
      },
    },
  };
  return (
    <motion.div
      initial={false}
      variants={contactMenuVariants}
      animate={isContactMenuShown ? "open" : "closed"}
      className="h-screen w-screen fixed top-0 right-0 bg-page px-8 pt-8"
    >
      <button
        className=""
        onClick={() => {
          setMobileNavMenuShown(false);
          setIsContactMenuShown(false);
        }}
      >
        <img src={xClose} alt="" />
      </button>
   
      <div className="pb-8 pt-10 lg:px-[12rem] lg:pb-[12rem] lg:pt-[8rem]">
        <div>
          <h6 className="text-lg font-light sm:text-[1.5rem] sm:pb-2">
            Reach out to us
          </h6>

          <a
            className="text-xl sm:text-[2.5rem] font-normal sm:tracking-[-0.04em] cursor-pointer"
            href="mailto:info@clprivateschool.com?subject=Hello&body=Hello, I'd like to enrol my child here "
            target="_blank"
            rel="noreferrer noopener"
          >
            info@clprivateschool.com
          </a>
        </div>

        <div className="mt-[3.5rem] sm:mt-[5rem]">

            <div className="text-[1.75rem] sm:text-[2.5rem] sm:tracking-[-0.04em] font-normal cursor-pointer">
            <a href="https://g.co/kgs/4PW8xd5">CL Private School, Gbegira Estate, Mowe, Ogun State</a>
            </div>


          <span className="text-[1.75rem] sm:text-[2.5rem] font-normal sm:tracking-[-0.04em] sm:mb-0 cursor-pointer">
            <a href="tel:+2349024326452">+234 902 432 6452</a>
          </span>

          {/* <div className="text-[1.75rem] sm:text-[2.5rem] sm:tracking-[-0.04em] font-normal cursor-pointer">
            <a href="tel:+2348023050672">+234 802 305 0672</a>
          </div> */}


          <div className="mt-4 sm:mt-[2rem]">
            <ul className="text-[1.1rem] font-light sm:text-[1.25rem] flex flex-wrap gap-2">
              <li>
                <a
                  href="https://wa.me/2349024326452"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  WhatsApp
                </a>
              </li>
              <li>･</li>
              <li>
                <a
                  href="https://facebook.com/clprivateschool"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Facebook
                </a>
              </li>
              <li>･</li>
              <li>
                <a
                  href="https://instagram.com/clprivateschool"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ContactUs;
