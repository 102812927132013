import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import acsi from "../assets/acsi.png";
import naps from "../assets/naps.png";
import Button from "../UIComponents/Button";

const Hero = () => {
  // This hero section has to be shifted by 96px to the top. because the header has position fixed
  //   Then Padding of 26px to the left and right
  return (
    <div className="bg-page">
      <div className="flex flex-col items-center">
        <div className="mt-[96px] px-7 sm:px-0 flex flex-col">
          <div className="mt-[94px] max-w-[280.21px] sm:max-w-[unset]">
            <h3 className="uppercase  font-light text-sm text-brownish tracking-[0.08em]">
              We are a Private Christian Nursery & Primary School
            </h3>
          </div>

          <div className="mt-4 sm:max-w-[29.25rem]">
            <h1 className="text-purpleish text-5xl leading-[3rem] md:text-[5rem] md:leading-[4.6875rem] tracking-tighter">
              Our Children, Our Future.
            </h1>
          </div>

          <div className="mt-5 sm:max-w-[32.5rem]">
            <p className="font-light text-base leading-[1.6rem] text-brownish">
              We are a friendly and family-oriented Nursery & Primary School for
              children ages 2 to 10 years. Our children have the best-ever day
              of exploration and discovery that helps them unfold with the help
              of our passionate Early Childhood Education Professional Staff.
            </p>
          </div>

          <div className="mt-[1.5rem]">
          <Button
             onClick={() => {
              window
                .open("https://wa.me/2349024326452", "_blank")
                .focus();
            }}
            text="Chat with Us"
            backgroundColor="bg-primary"
            color="text-white"
          />
        </div>

          <div className="mt-[3.75rem] mb-8 flex flex-col  sm:max-w-[32.5rem] sm:flex-row sm:items-center sm:justify-between">
            {/* For that underline, we would use an after pseudo element to tidy it */}
            <div className=" relative after:absolute after:bottom-2 after:left-[100px] after:content-[''] after:w-[160px] after:h-[1px] after:bg-underline">
              <p className="text-base text-brownish">Member of </p>
            </div>

            {/* Associates Container */}
            <div className="flex item-center gap-7 items-center">
              <div>
                <LazyLoadImage
                  effect="blur"
                  src={acsi}
                  width={93}
                  height={41}
                  alt="ACSI logo"
                />
              </div>

              <div>
                <LazyLoadImage
                  effect="blur"
                  src={naps}
                  width={67}
                  height={57}
                  alt="NAPS logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
