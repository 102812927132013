import { Suspense, useEffect, lazy } from "react";

import Hero from "../../components/Hero";
import Navbar from "../../components/Navbar";

// import FunAndFriendlyGallery from "../../components/FunAndFriendlyGallery";
// import BibleQuotes from "../../components/BibleQuotes";
// import FloatingGallery from "../../components/FloatingGallery";
// import MontessoriGallery from "../../components/MontessoriGallery";
// import ContactCard from "../../components/ContactCard";
// import Footer from "../../components/Footer";
import ContactUs from "../../components/ContactUs";

const FunAndFriendlyGallery = lazy(() =>
  import("../../components/FunAndFriendlyGallery")
);
const BibleQuotes = lazy(() => import("../../components/BibleQuotes"));
const FloatingGallery = lazy(() => import("../../components/FloatingGallery"));
const MontessoriGallery = lazy(() =>
  import("../../components/MontessoriGallery")
);
const ContactCard = lazy(() => import("../../components/ContactCard"));
const Footer = lazy(() => import("../../components/Footer"));
// const ContactUs = lazy(() => import("../../components/ContactUs"));

function Home(props) {
  const {
    isContactMenuShown,
    setIsContactMenuShown,
    setMobileNavMenuShown,
    mobileNavMenuShown,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Navbar
        setIsContactMenuShown={setIsContactMenuShown}
        mobileNavMenuShown={mobileNavMenuShown}
        setMobileNavMenuShown={setMobileNavMenuShown}
      />
      <Hero />
      <Suspense fallback={<div />}>
        <FloatingGallery />
      </Suspense>

      <Suspense fallback={<div />}>
        <BibleQuotes />
      </Suspense>

      <Suspense fallback={<div />}>
        <FunAndFriendlyGallery />
      </Suspense>

      <Suspense fallback={<div />}>
        <MontessoriGallery />
      </Suspense>

      <Suspense fallback={<div />}>
        <ContactCard setIsContactMenuShown={setIsContactMenuShown} />
      </Suspense>

      <ContactUs
        isContactMenuShown={isContactMenuShown}
        setIsContactMenuShown={setIsContactMenuShown}
        setMobileNavMenuShown={setMobileNavMenuShown}
      />
      <Footer />
    </>
  );
}

export default Home;
